<script setup lang="ts">
import type { CmsBlockImageText } from "@shopware-pwa/composables-next";
import { useCmsBlock } from "#imports";

const props = defineProps<{
  content: CmsBlockImageText;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");

const isRightContentImage = computed(() => {
  return rightContent.type === 'image'
})

</script>
<template>
  <div class="image-text-grid">
    <div :class="{ 'order-1 order-md-0': isRightContentImage }">
      <CmsGenericElement :content="leftContent" />
    </div>
    <div :class="{ 'order-0 order-md-1': isRightContentImage }">
      <CmsGenericElement :content="rightContent" />
    </div>
  </div>
</template>

<style scoped>
  .image-text-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  
  @media screen and (min-width: 960px) {
    .image-text-grid {
      grid-template-columns: 1fr 1fr;
      gap: 6rem;
    }
  }
</style>

